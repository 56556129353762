// a react component to inject the returned html
import React, { Component } from "react";
import "whatwg-fetch";

export default class StaticContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      __html: "",
    };
  }

  componentDidMount() {
    // fetch the HTML fragment with a local API request
    let contentURL = `${window.location.origin}/static/${this.props.content}.html`;
    fetch(contentURL)
      .then((response) => response.text())
      .then((content) => {
        // dangerouslySetInnerHTML requires using an object with an `__html` key
        console.log("Got Content:" + content);
        this.setState({
          __html: content,
        });
      })
      .catch((err) => {
        console.log("Error " + err);
        // handle the error
      });
  }

  render() {
    return <div dangerouslySetInnerHTML={this.state} />;
  }

  // render() {
  //   return <div dangerouslySetInnerHTML={this.state} />;
  // }
}
