// a React Component that includes the header and footer
import React from "react";

import StaticContent from "./StaticContent";
import { useParams } from "react-router-dom";

const StaticContainer = () => {
  let { id } = useParams();

  return (
    <>
      <StaticContent content={id} />
    </>
  );
};

export default StaticContainer;
