import React from "react";
import "./App.css";
import {
  Container,
  Row,
  Col,
  Jumbotron,
  Image,
  NavDropdown,
} from "react-bootstrap";

class Home extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <Container fluid className="App">
        <Jumbotron>
          <Row>
            <Col sm={4}>
              <Image src="/passionists.svg" alt="Letters of St Paul" fluid />
            </Col>
            <Col sm={8}>
              <h1>The Letters of St Paul of the Cross</h1>
              <p>
                Welcome to the Letters of St. Paul of the Cross. This site will
                allow you to discover the letters of St. Paul of the Cross using
                search techniques. You will also be able to exchange your
                discoveries with other scolars but more on that later.
              </p>
              <p>
                Using this site you can quickly search for information within
                the Letters of St. Paul of the Cross. The letters are searched
                using a fast search engine that allows you to search for words,
                phrases and combinations of the two.
              </p>
              <p>
                The following introductions provide valuable context and
                background to assist in your study of the Letters of St. Paul of
                the Cross.
              </p>
              <ul>
                <li>
                  <a href="/introduction/general">General Introduction</a>
                </li>
                <li>
                  <a href="/introduction/1">
                    Introduction to Volume One Letters 1 - 510
                  </a>
                </li>
                <li>
                  <a href="/introduction/2">
                    Introduction to Volume Two Letters 511 - 1177
                  </a>
                </li>
                <li>
                  <a href="/introduction/2">
                    Introduction to Volume Three Letters 1178 - 2069
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Jumbotron>
      </Container>
    );
  }
}

export default Home;
