import React from "react";
import "./App.css";
import { Container, Row } from "react-bootstrap";

class About extends React.Component {
  componentDidMount(props) {
    console.log("Props: " + props);
  }

  render() {
    return (
      <Container fluid className="App">
        <Row>
          <h2>Introduction</h2>
          <p>
            In the year of Jubilee 2000 the Holy Cross Province in the United
            States published a three volume edition of the letters of St. Paul
            of the Cross. This was the first time that english speakers had
            access to all the existing letters of the founder of the Passionist
            Congregation. These volumes have been gratefully received and we all
            owe the translation team of Roger Mercurio C.P., Frederick Sucher
            C.P. and the editing team of Laurence Finn C.P. and Donald Webber
            C.P. a heartfelt vote of thanks for their achievement.
          </p>
          <p>
            Once we finally had access to the letters in English another set of
            questions arose. How best to access them and what about indices to
            aid further research? To this end conversations inevitably led to
            the possibility of creating a searchable database which could make
            the same volumes available in an electronic format. The Holy Cross
            Province gave its permission to convert the texts to html format and
            the work of producing a companion volume was able to begin.
          </p>
          <p>
            In addition to browsing through the collection of the letters this
            program contains a powerful search engine that will enable you to
            search across the entire collection of the letters for various
            themes and phrases.
          </p>
        </Row>
      </Container>
    );
  }
}

export default About;
