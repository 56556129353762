import React from "react";
import "./App.css";
import { Container } from "react-bootstrap";
import StaticContainer from "./StaticContainer";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
  useParams,
} from "react-router-dom";

import Search from "./Search";
import About from "./About";
import Browse from "./Browse";
import Home from "./Home";
import Navigation from "./Navigation";

class App extends React.Component {
  render() {
    return (
      <Router>
        <Navigation />
        <Container fluid className="App">
          <Switch>
            <Route path="/search" component={Search} />
            <Route
              path="/browse"
              component={() => <Browse documentIndex={this.documentIndex} />}
            />
            <Route path="/about" component={About} />
            <Route exact path="/introduction/:id" component={StaticContainer} />
            <Route path="/" exact component={Home} />
          </Switch>
        </Container>
      </Router>
    );
  }
}

export default App;
