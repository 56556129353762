import React, { Component } from "react";
import Row from "react-bootstrap/Row";

class DocumentSummary extends Component {
  render() {
    if (this.props.document != null) {
      return (
        <p>
          <a
            href={
              "/content/" +
              this.props.document.file +
              (this.props.search ? "?search=" + this.props.search.text : "")
            }
            target="_empty"
          >
            {"  "}
            <b>{this.props.document.title}</b> To{" "}
            <b>{this.props.document.recipient}</b> -{" "}
            <b>{this.props.document.date}</b>
          </a>{" "}
          <br />
          Written from: <b>{this.props.document.location}</b>
        </p>
      );
    } else {
      return <Row>Undefined</Row>;
    }
  }
}

export default DocumentSummary;
