import React from "react";
import "./App.css";
import Container from "react-bootstrap/Container";
import _, { values } from "lodash";
import { Tabs, Tab } from "react-bootstrap";
import BrowseDocumentIndex from "./BrowseDocumentIndex";

class Browse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentIndex: [],
    };
  }

  documentsByRecipient(docs) {
    var sortedDocs = _.sortBy(docs, function (o) {
      return parseInt(o.title);
    });
    return _.groupBy(sortedDocs, "recipient");
  }

  documentsByYear(docs) {
    var sortedDocs = _.sortBy(docs, function (o) {
      return parseInt(o.title);
    });
    return _.groupBy(sortedDocs, "year");
  }
  documentsByLocation(docs) {
    var sortedDocs = _.sortBy(docs, function (o) {
      return parseInt(o.title);
    });
    return _.groupBy(sortedDocs, "location");
    // return _.groupBy(docs, "location");
  }

  componentDidMount() {
    fetch("./indexes/doc_index.json")
      .then((response) => response.json())
      .then((data) => {
        const documentIndex = data;
        const locations = this.documentsByLocation(documentIndex);
        const years = this.documentsByYear(documentIndex);
        const recipients = this.documentsByRecipient(documentIndex);
        this.setState({
          locationIndex: locations,
          yearIndex: years,
          recipientIndex: recipients,
        });
        //        console.log("Documents Index: " + data);
        // this.setState({ documentIndex: data });
        console.log("State: " + this.state);
      });
  }

  render() {
    return (
      <Container className="Browse">
        <div className="border-container">
          <Tabs defaultActiveKey="location" id="uncontrolled-tab-example">
            <Tab eventKey="location" title="By Location">
              <BrowseDocumentIndex index={this.state.locationIndex} />
            </Tab>
            <Tab eventKey="year" title="By Year">
              <BrowseDocumentIndex index={this.state.yearIndex} />
            </Tab>
            <Tab eventKey="recipient" title="By Recipient">
              <BrowseDocumentIndex index={this.state.recipientIndex} />
            </Tab>
          </Tabs>
        </div>
      </Container>
    );
  }
}

export default Browse;
