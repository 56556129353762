import React from "react";
import "./App.css";
import { Container, Row, Col, Badge } from "react-bootstrap";
import SearchPanel from "./SearchPanel";
import { Index } from "elasticlunr";
import DocumentSummary from "./DocumentSummary";
import _ from "lodash";
import queryString from "query-string";

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchIndex: [],
      documentIndex: [],
      documentSearchResults: [],
      searchParams: {},
    };
  }

  componentDidMount() {
    fetch("./indexes/search_index.json")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ searchIndex: Index.load(data) });

        fetch("./indexes/doc_index.json")
          .then((response) => response.json())
          .then((data) => {
            // rebuild index based on the document titles - builds object with title as keys
            const newIndex = _.keyBy(data, "title");
            this.setState({ documentIndex: newIndex });

            // dump any search params
            const values = queryString.parse(this.props.location.search);
            console.log("Search ready for action:", values);
            if (values) {
              // load the search state from our query parameters
              this.search(values);
            }
          });
      });
  }

  search(searchParams) {
    console.log("Search Controller params:", searchParams);
    // assemble the search string
    var searchWords = "";
    var fieldBoost = {};

    this.setState({
      searchParams: searchParams || {},
    });

    if (searchParams.text && searchParams.text.length > 0) {
      searchWords = searchWords + " " + searchParams.text;
      fieldBoost.body = { boost: 1, bool: "AND" };
    }
    console.log("Search Terms: " + searchWords);

    const searchResults = this.state.searchIndex.search(searchWords, {
      fields: { body: { boost: 1 } },
      bool: "AND",
    });

    // update our history with the search url
    const qstring = queryString.stringify(searchParams);
    // add search terms to URL
    this.props.history.push({ pathname: "/search", search: "?" + qstring });

    // DEBUG
    // console.log("No results: " + JSON.stringify(searchResults));
    // build a list of the result documents for rendering
    const documentIndex = this.state.documentIndex;
    var documentSearchResults = searchResults.map((result) => {
      return documentIndex[result.ref];
    });

    if (searchParams.year) {
      documentSearchResults = _.filter(documentSearchResults, function (o) {
        return o.year.startsWith(searchParams.year);
      });
    }
    if (searchParams.recipient) {
      documentSearchResults = _.filter(documentSearchResults, function (o) {
        return o.recipient
          .toLowerCase()
          .startsWith(searchParams.recipient.toLowerCase());
      });
    }
    if (searchParams.location) {
      documentSearchResults = _.filter(documentSearchResults, function (o) {
        return o.location
          .toLowerCase()
          .startsWith(searchParams.location.toLowerCase());
      });
    }

    documentSearchResults = _.sortBy(documentSearchResults, function (o) {
      return parseInt(o.title);
    });

    // DEBUG
    // console.log("documentSearchResults: " + JSON.stringify(searchResults));
    this.setState({ documentSearchResults: documentSearchResults });

    if (this._searchPanel) {
      this._searchPanel.updateSearchCriteria(searchParams);
    }
  }

  render() {
    const searchPanelContent =
      this.state.searchIndex && this.state.documentIndex ? (
        <SearchPanel
          controller={this}
          ref={(comp) => (this._searchPanel = comp)}
        />
      ) : (
        "Loading..."
      );

    return (
      <Container className="Search">
        <div className="border-container">
          <h2>Search criteria</h2>
          <Row>{searchPanelContent}</Row>
        </div>
        <div className="border-container">
          <h2>
            Results&nbsp;&nbsp;
            <Badge variant="secondary">
              {this.state.documentSearchResults.length > 0
                ? this.state.documentSearchResults.length
                : ""}
            </Badge>
          </h2>
          {this.state.documentSearchResults.length === 0 ? (
            <Row>
              <Col>No matching letters.</Col>
            </Row>
          ) : (
            this.state.documentSearchResults.map((doc) => {
              return (
                <Row>
                  <Col xs={12}>
                    <DocumentSummary
                      key={doc.title}
                      document={doc}
                      search={this.state.searchParams}
                    />
                  </Col>
                </Row>
              );
            })
          )}
        </div>
      </Container>
    );
  }
}

export default Search;
