import React, { Component } from "react";
import { Form, Button, Container, Col, Row } from "react-bootstrap";

class SearchPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      location: "",
      year: "",
      recipient: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    //    const target = event.target;
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  }

  updateSearchCriteria(searchParams) {
    this.setState({
      text: searchParams.text,
      location: searchParams.location,
      year: searchParams.year,
      recipient: searchParams.recipient,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const searchParams = {
      text: this.state.text,
      location: this.state.location,
      year: this.state.year,
      recipient: this.state.recipient,
    };
    console.log("SearchPanel params:", searchParams);
    // pass search parameters to our controller and search
    this.props.controller.search(searchParams);
  }

  render() {
    return (
      <Container>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group as={Row} controlId="phraseGroup">
            <Form.Label column sm="2">
              Search
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="text"
                type="text"
                value={this.state.text}
                onChange={this.handleInputChange}
              />
              <Form.Text className="text-muted">
                a phrase like 'sea of love'
              </Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="locationGroup">
            <Form.Label column sm="2">
              Where written
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="location"
                type="text"
                value={this.state.location}
                onChange={this.handleInputChange}
              />
              <Form.Text className="text-muted">a place like Rome</Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="recipientGroup">
            <Form.Label column sm="2">
              Recipient
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="recipient"
                type="text"
                value={this.state.recipient}
                onChange={this.handleInputChange}
              />
              <Form.Text className="text-muted">
                recipient like Agnes or 'Agnes Grazi'
              </Form.Text>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="yearGroup">
            <Form.Label column sm="2">
              Year
            </Form.Label>
            <Col sm="10">
              <Form.Control
                name="year"
                type="text"
                value={this.state.year}
                onChange={this.handleInputChange}
              />
              <Form.Text className="text-muted">
                year or years like 1725 or 176*
              </Form.Text>
            </Col>
          </Form.Group>
          <Button variant="primary" type="submit">
            Search
          </Button>
        </Form>
      </Container>
    );
  }
}

export default SearchPanel;
