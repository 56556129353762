import React, { Component } from "react";
import { Accordion, Card, Badge } from "react-bootstrap";
import DocumentSummary from "./DocumentSummary";

class BrowseDocumentIndex extends Component {
  render() {
    if (!this.props.index) {
      return <p>Loading...</p>;
    } else {
      return (
        <Accordion defaultActiveKey="0">
          {Object.keys(this.props.index)
            .sort((a, b) => {
              return a > b;
            })
            .map((key, index) => {
              return (
                <Card key={key}>
                  <Accordion.Toggle
                    as={Card.Header}
                    variant="link"
                    eventKey={key}
                  >
                    {key}{" "}
                    <Badge variant="secondary">
                      {this.props.index[key].length}
                    </Badge>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={key}>
                    <Card.Body>
                      {this.props.index[key].map((doc) => {
                        return (
                          <DocumentSummary key={doc.title} document={doc} />
                        );
                      })}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
        </Accordion>
      );
    }
  }
}

export default BrowseDocumentIndex;
