import React, { Component } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import pjson from "../package.json";

console.log(pjson.version);

class Navigation extends Component {
  render() {
    return (
      <Navbar bg="light" variant="light">
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              src="/passionists.svg"
              width="17"
              height="30"
              className="d-inline-block align-top"
              alt="Letters of St Paul"
            />{" "}
            Letters of St Paul of the Cross
          </Navbar.Brand>
        </LinkContainer>
        <Nav className="mr-auto">
          <LinkContainer to="/search">
            <Nav.Link>Search</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/browse">
            <Nav.Link>Browse</Nav.Link>
          </LinkContainer>
          <NavDropdown title="Introductions" id="basic-nav-dropdown">
            <NavDropdown.Item href="/introduction/general">
              General
            </NavDropdown.Item>
            <NavDropdown.Item href="/introduction/1">
              Volume One Letters 1 - 510
            </NavDropdown.Item>
            <NavDropdown.Item href="/introduction/2">
              Volume Two Letters 511 - 1177
            </NavDropdown.Item>
            <NavDropdown.Item href="/introduction/3">
              Volume Three Letters 1178 - 2069
            </NavDropdown.Item>
          </NavDropdown>

          <LinkContainer to="/about">
            <Nav.Link>About</Nav.Link>
          </LinkContainer>
        </Nav>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>Version {pjson.version}</Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Navigation;
